<template>
  <div v-editable="blok" class="grid-item" :style="[spanForGrid]">
    <StoryblokComponent
      v-for="childBlok in blok.components"
      :key="childBlok._uid"
      :blok="childBlok"
      :card-padding-data="cardPaddingData"
    />
  </div>
</template>

<script lang="ts" setup>
import type {
  SbGridItemStoryblok,
  SbPluginResponsiveSpacingNumbers,
} from '@/types'

interface Props {
  blok: SbGridItemStoryblok
  cardPaddingData?: SbPluginResponsiveSpacingNumbers
}
const props = defineProps<Props>()

const spanForGrid = computed(() => {
  return {
    '--column-desktop': `${props.blok.item_span.desktop}`,
    '--column-tablet': `${props.blok.item_span.tablet}`,
    '--column-mobile': `${props.blok.item_span.mobile}`,
  }
})
</script>

<style lang="scss" scoped>
.grid-item {
  display: flex;
  flex-direction: column;

  --column-mobile: 12;
  --column-tablet: 12;
  --column-desktop: 6;

  grid-column: span var(--column-mobile);

  @media (min-width: $breakpoint-md) {
    grid-column: span var(--column-tablet);
  }

  @media (min-width: $breakpoint-lg) {
    grid-column: span var(--column-desktop);
  }
}
</style>
